import { createContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { endpoints } from "../services/api";
import toast from "react-hot-toast";
import CryptoJS from 'crypto-js';
import {  setSuperUser , setStaff, setIsApproved ,setIsTwitterAuth, setAccessToken} from "../reducer/slices/authSlice";
import { useNavigate } from "react-router-dom";


export const AppContext = createContext();

export default function AppContextProvider({ children }) {

  const superUserItem = ["PROMPT MANAGEMENT" , "ACCOUNT MANAGEMENT"];
  const staffUserItem = ["ACCOUNT FEEDS","TOPICS","GENERATE","AUTOMATE","SCHEDULED","REPORT LOGS"];
const normalUserItem = ["ACCOUNT FEEDS","TOPICS","GENERATE","AUTOMATE","SCHEDULED"];

  const dispatch = useDispatch();

  const {is_superUser , is_staff , accessToken , refreshToken , is_twitter_authorized} = useSelector((state)=>state.auth);

  const [showSidebar , setShowSidebar] = useState(false);

  // this count is for sidebar count
  const [count , setCount] = useState(0);

  const [ showReportPopup , setShowReportPopup] = useState(false);

  const [ reportPopupData , setReportPopupData] = useState([]);

 const [showschedulePostPopup , setShowSchedulePopup] = useState(false);

 const [showDeletePopup , setShowDeletePopup] = useState(false);

 const [deleteId , setDeleteId] = useState(null);

const [currentAccPage , setCurrentAccPage] = useState(0);

 const [showGenerPopup , setShowGenerPopup] = useState(false);

 const [schedulePopupData , setSchedulePopupData] = useState(null);

//  this is for dashboard pages
const [currentPage , setCurrentPage] = useState("");

const [allUser, setAllUsers] = useState([]);

  const [loading, setLoading] = useState(false);

  const [generatePopupData , setGeneratePopupData] = useState([]);

  const [allgenpopupdata , setAllGenPopupData] = useState("");

  // this is for all api details
  const [allApiDetail , setAllApiDetails] = useState({
        show:"All",
       page: 1 ,
      page_size:"10" ,
      params:"",
      ordering:""
  })

  const [accountAllData , setAccAllData] = useState("");

  const [userSidebar , setUserSidebar] = useState({
 username:"",
  bio:"",
  profile_photo:""   ,
  name:""
})

const navigate = useNavigate();

  const logoutCondition = (noNaivagate = true)=>{

    localStorage.removeItem("autoAiRefreshToken" );
    localStorage.removeItem("autoAiaccessToken");
    localStorage.removeItem("autoAiis_approved");
    localStorage.removeItem("autoAiis_twitter_authorized" );
    localStorage.removeItem("autoAiis_staff" );
    localStorage.removeItem("autoAiis_superuser");
    sessionStorage.removeItem("autoAiCurrentPage");

     if(noNaivagate){
       navigate("/");
      }


  }

  const covertBooleanDecrpte3d = (value)=>{
    const decryptedData = CryptoJS.AES.decrypt(value, 'secretKey').toString(CryptoJS.enc.Utf8);
    const decryptedBoolean = decryptedData === 'true';

     return decryptedBoolean;
  }

  const booleanEncrpt = (value)=>{

    const booleanString = value.toString();
    const encryptedData = CryptoJS.AES.encrypt(booleanString, 'secretKey').toString();

    return encryptedData;

  }

  const textEncrpted = (value)=>{
    const encryptedData = CryptoJS.AES.encrypt(value, 'secretKey').toString();
 return encryptedData;
  }

  const textdecrpted = (value)=>{
    const decryptedData = CryptoJS.AES.decrypt(value, 'secretKey').toString(CryptoJS.enc.Utf8);
    return decryptedData;
  }

  const deleteAccessToken = () => {
    localStorage.removeItem('autoAiaccessToken');
  };

  const checkUserHandler2 = async(access)=>{

    try{

        const response = await fetch(endpoints.CHECK_USERDETAILS_API , {

            method:"GET",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${access}`,
              },
        })

        const formattedResponse = await response.json();

        if(response.status === 200){

        const {is_staff , is_superuser , is_approved , is_twitter_authorized ,name, username , bio , profile_photo} = formattedResponse;

             if(username){
              setUserSidebar((prev)=>({
                ...prev ,
                username: username
              }))
             }

             if(name){
              setUserSidebar((prev)=>({
                ...prev ,
                name: name
              }))
             }

             if(bio){
              setUserSidebar((prev)=>({
                ...prev ,
                bio: bio
              }))
             }

             if(profile_photo){
              const imageUrl = profile_photo;


              setUserSidebar((prev)=>({
                ...prev ,
                profile_photo: imageUrl
              }))
             }

            dispatch(setSuperUser(is_superuser))
            dispatch(setStaff(is_staff));
            dispatch(setIsApproved(is_approved));
            dispatch(setIsTwitterAuth(is_twitter_authorized));

            localStorage.setItem("autoAiis_approved" , JSON.stringify(booleanEncrpt(is_approved)));
            localStorage.setItem("autoAiis_twitter_authorized" , JSON.stringify(booleanEncrpt(is_twitter_authorized)));
            localStorage.setItem("autoAiis_staff" , JSON.stringify(booleanEncrpt(is_staff)));
            localStorage.setItem("autoAiis_superuser" , JSON.stringify(booleanEncrpt(is_superuser)));



        }
        else if(response.status === 401 || response.status === 403 ){

           await generateNewAccessToken();
        }


    } catch(error){
        console.log(error);
    }

  }

   // refresh token handler
 async function generateNewAccessToken(){

  try {
    const response = await fetch(endpoints.REFRESH_TOKEN_API, {
      method: "POST",

      headers: {
        "content-type": "application/json",
      },

      body: JSON.stringify({ refresh: refreshToken }),
    });


    if (response.status === 200) {
      const formattedResponse = await response.json();
      const { access } = formattedResponse;

    localStorage.setItem("autoAiaccessToken" , JSON.stringify(textEncrpted(access)));

      dispatch(setAccessToken(access));
        return  checkUserHandler2(access);

    }
    else if(response.status === 403 || response.status === 401) {
      logoutCondition();
    }
    else {
      logoutCondition();
    }

     } catch (error) {
    console.log(error);
  }
};

const checkUserHandler = async()=>{

  try{

      const response = await fetch(endpoints.CHECK_USERDETAILS_API , {

          method:"GET",
          headers: {
              "content-type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
      })

      const formattedResponse = await response.json();

      if(response.status === 200){

      const {is_staff , is_superuser , is_approved , is_twitter_authorized ,name, username , bio , profile_photo} = formattedResponse;

           if(username){
            setUserSidebar((prev)=>({
              ...prev ,
              username: username
            }))
           }

           if(name){
            setUserSidebar((prev)=>({
              ...prev ,
              name: name
            }))
           }

           if(bio){
            setUserSidebar((prev)=>({
              ...prev ,
              bio: bio
            }))
           }

           if(profile_photo){
            const imageUrl = profile_photo;


            setUserSidebar((prev)=>({
              ...prev ,
              profile_photo: imageUrl
            }))
           }

          dispatch(setSuperUser(is_superuser))
          dispatch(setStaff(is_staff));
          dispatch(setIsApproved(is_approved));
          dispatch(setIsTwitterAuth(is_twitter_authorized));

          localStorage.setItem("autoAiis_approved" , JSON.stringify(booleanEncrpt(is_approved)));
          localStorage.setItem("autoAiis_twitter_authorized" , JSON.stringify(booleanEncrpt(is_twitter_authorized)));
          localStorage.setItem("autoAiis_staff" , JSON.stringify(booleanEncrpt(is_staff)));
          localStorage.setItem("autoAiis_superuser" , JSON.stringify(booleanEncrpt(is_superuser)));



      }
      else if(response.status === 401 || response.status === 403 ){

         await generateNewAccessToken();
      }


  } catch(error){
      console.log(error);
  }

}


 // get all users api
    const getAllUsers = async (addPath , gather=false) => {

      setLoading(true);
      try {
        const response = await fetch(endpoints.GET_ALL_USERS_API + addPath , {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        });


        const formattedResponse = await response.json();

        if (response.status === 200) {

          if(gather){
            setAllUsers((prev) => [
              ...prev,
              ...formattedResponse?.results,
            ]);
          }
          else {
            setAllUsers(formattedResponse?.results);
          }

          setAccAllData(formattedResponse);

        } else{
         generateNewAccessToken();
        }


      } catch (error) {
        console.log(error);
      }

      setLoading(false);

    };



const func1 = async()=>{
  if(refreshToken){
    checkUserHandler();
   }
}

useEffect( ()=>{
  func1();
},[])



useEffect(()=>{
  if(refreshToken !== null && accessToken === null){
    generateNewAccessToken();
    }
    else if(refreshToken !== null){
      checkUserHandler();
    }

} , [accessToken])


useEffect(()=>{
  let autoAiPage = sessionStorage.getItem("autoAiCurrentPage") || "";
   if(is_superUser){

     if(superUserItem.includes(autoAiPage)){
       setCurrentPage(autoAiPage);
      }
      else {
        setCurrentPage("PROMPT MANAGEMENT");
        sessionStorage.setItem("autoAiCurrentPage","PROMPT MANAGEMENT");

      }
    }
  else if(is_staff) {

     if(staffUserItem.includes(autoAiPage)){
       setCurrentPage(autoAiPage)
     }
     else {
       setCurrentPage("ACCOUNT FEEDS")
       sessionStorage.setItem("autoAiCurrentPage","ACCOUNT FEEDS");
      }

   }
   else {
    if(normalUserItem.includes(autoAiPage)){
      setCurrentPage(autoAiPage);
    }
    else {
      setCurrentPage("ACCOUNT FEEDS")
      sessionStorage.setItem("autoAiCurrentPage","ACCOUNT FEEDS");
     }
   }

},[is_staff , is_superUser , is_twitter_authorized]);


const [currentPrompt , setCurrentPrompt] = useState(null);

const [state , setState] = useState("");

const [charData , setCharData] = useState({
  max_char :"280",
  thread_size :"1",

})

const [loadingData , setLoadingData]  = useState({
  loading1:false ,
  loading2: false ,
  loading3: false,
  loading4: false ,
})

// this is for prompt

const [promptData, setPromptData] = useState({
  user_prompt: ["", "", ""],
  max_char: "280",
  thread_size: "1"
});

const [reRenderCont , setReRenderCont] = useState(false);

const [schdDateTime , setSchDateTime] = useState(null);

const [genLoading , setGenLoading] = useState(false);

const [genPopupText , setGenPopupText] = useState("Please Do not leave the page");

const controller = new AbortController();
const signal = controller.signal;

// for generate tweets
const generatingTweetHandler = async()=>{
  try{
     setGenLoading(true);

    const {user_prompt} = currentPrompt;

    if(user_prompt === ""){
toast.error("the prompt is empty ");
setLoadingData({
  loading1:false ,
  loading3: false ,
  loading2:false  ,
  loading4:false
})
    }

    const formData = new FormData();

formData.append('user_prompt', currentPrompt?.user_prompt);
formData.append('max_char', promptData?.max_char);
formData.append('thread_size', promptData?.thread_size);

    const response = await fetch(endpoints.TWEET_GENERATING_API, {
      method: "POST",

      headers: {
                  Authorization: `Bearer ${accessToken}`,
      },

      body: formData,
      signal
    });


     if(response.status === 202){
      setGenPopupText("Now you can leave the page , it will take few minutes");

       if(showGenerPopup){
        setTimeout(async()=>{
          await generatingTweetHandler();
        }, 1500);

        }

   else {
     return ;
   }

     }
   else if(response.status === 200){

    const formattedResponse = await response.json();
      setState(null);
      const newGeneratePopupData = formattedResponse?.tweets_generated.map((item) => {
        return item;
      });
      setGeneratePopupData(newGeneratePopupData);

      setAllGenPopupData(formattedResponse);

      setGenLoading(false);

      setLoadingData({
        loading1:false ,
        loading3: false ,
        loading2:false  ,
        loading4:false
      })

    }
    else if(response.status == 401){
      logoutCondition();
    }

  } catch(error){
    console.log(error);


  }
}

const [data , setData] = useState([]);

const [scLoading , setSchLoading] = useState(false);

const fetchAllShedule = async()=>{

  setSchLoading(true);
  try{

    const response = await fetch(endpoints.FETCH_ALL_SCHEDULE_API , {

      method:"GET",
      headers: {
          Authorization: `Bearer ${accessToken}`,
        },
  })

  const formattedResponse = await response.json();

  if(response.status === 200){
    setData(formattedResponse);
     if(formattedResponse?.length > 0){
      setSchDateTime(formattedResponse?.next_generate_time);
    }
    else {
       setSchDateTime(null);

     }
  }


  } catch(error){
    console.log(error);
  }
  setSchLoading(false);
}

useEffect(() => {

  if (state === "running") {
    setGenPopupText("Please Do not leave the page");
         setShowGenerPopup(true);

        }
      }, [state]);

      useEffect(()=>{

        if(showGenerPopup && state === "running"){
    generatingTweetHandler();

  }
  else {
    // Cancel the API call if it's in progress
    controller.abort();
  }
  // Cleanup function to abort the fetch when component unmounts or dependencies change
  return () => controller.abort();

},[showGenerPopup , state])





  const value = {
    showSidebar ,
     setShowSidebar ,
     showschedulePostPopup ,
     setShowSchedulePopup,
     showGenerPopup,
     setShowGenerPopup ,
     count ,
     setCount  ,
     currentPage ,
     setCurrentPage,
     showDeletePopup,
     setShowDeletePopup ,
     deleteId ,
     setDeleteId ,
    loading ,
    getAllUsers,
    allUser ,
    setAllUsers ,
   logoutCondition,
   currentAccPage ,
   setCurrentAccPage ,
   allApiDetail ,
   setAllApiDetails ,
   accountAllData ,
   setAccAllData ,
   setLoading,
   generateNewAccessToken ,
   checkUserHandler ,
   userSidebar,
   generatePopupData ,
   setGeneratePopupData,
   generatingTweetHandler ,
   currentPrompt ,
   setCurrentPrompt ,
   state , setState ,
   charData , setCharData ,
   loadingData , setLoadingData ,
   allgenpopupdata , setAllGenPopupData  ,
   reRenderCont , setReRenderCont,
   showReportPopup , setShowReportPopup ,
   reportPopupData , setReportPopupData , schedulePopupData , setSchedulePopupData ,
   fetchAllShedule , data , setData , scLoading ,
   promptData, setPromptData , genLoading , setGenLoading ,
   genPopupText , setGenPopupText ,
   covertBooleanDecrpte3d , booleanEncrpt ,   textEncrpted ,textdecrpted ,
   schdDateTime

  };


  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}
