import { useContext, useEffect } from "react";
import "./authorize.css"
import { endpoints } from "../services/api";
import { useSelector } from "react-redux";
import { AppContext } from "../Context/AppContext";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

function AuthorizePage(){

  const navigate = useNavigate();

    const {accessToken} = useSelector((state)=>state.auth);

    const {generateNewAccessToken , checkUserHandler} = useContext(AppContext);

    const authenticationAPIHandler = async(codeValue , stateValue)=>{

      await generateNewAccessToken();

 try{
    const response = await fetch(endpoints.TWITTER_AUTHENTICATE_API , {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ code: codeValue , state: stateValue }),
      });
      if(response.status === 200){
        await checkUserHandler();
        toast.success("Successfuly Authorized");
navigate("/dashboard");

      }
      else {
        navigate("/");
      }

 } catch(error){
    console.log(error);
 }
    }

    useEffect(() => {  
    
          const urlParams = new URLSearchParams(window.location.search);
    
        
        const codeValue = urlParams.get('code');
        const stateValue = urlParams.get('state');
    
     

        if(codeValue && stateValue){
          authenticationAPIHandler(codeValue , stateValue);

        }
        else {
          // toast.error("Invalid Error");
          navigate("/");
        }
  
      }, []); //

    return (
        <div className="loaderWrap">
<span class="loader"></span>

        </div>
    )
}

export default AuthorizePage;